import React, { useEffect, useRef, useState } from "react";
import Moment from "react-moment";

import { priceFormat } from "utils/format";

import { ProductHead } from "../ProductHead";

import "./row.scss";

export const PAGE_HEIGHT = 745;

export const PAGE_HEAD_HEIGHT = 70;

export const Row = ({ product, utd, setPage, parent }) => {
  const ref = useRef(null);
  const [newPage, setNewPage] = useState(false);

  useEffect(() => {
    if (ref && parent) {

      const page = Math.ceil((ref.current.getBoundingClientRect().top - parent) / PAGE_HEIGHT);



      const pageHeight = page > 1 ? PAGE_HEIGHT - PAGE_HEAD_HEIGHT : PAGE_HEIGHT;

      const top = ref.current.getBoundingClientRect().top + ((page - 1) * PAGE_HEAD_HEIGHT) - parent;
      let bottom = ref.current.getBoundingClientRect().bottom + ((page - 1) * PAGE_HEAD_HEIGHT) - parent;

      console.log('index', product?.index);
      console.log('top', top);
      console.log('bottom', bottom);
      console.log('pageHeight', pageHeight);
      console.log('PAGE_HEIGHT + (pageHeight * (page - 1)))', PAGE_HEIGHT + (pageHeight * (page - 1)));
      console.log('page', page);

      if (
        bottom >= (PAGE_HEIGHT + (pageHeight * (page - 1)))
        &&
        top <= (PAGE_HEIGHT + (pageHeight * (page - 1)))
      ) {
        setNewPage(true);
        if (setPage) {
          setPage(prev => prev + 1);
        }
      }

    }

  }, [ref, parent]);

  return <>


    {newPage && <>
      <tr className="pageSeparator"><td colSpan={21}></td></tr>
      <tr className="headTitle pageBreakBefore">
        <td colSpan={21}>
          Универсальный передаточный документ № {utd.number} от {utd.shippingDate && <Moment format="D MMMM Y">{utd.shippingDate}</Moment>} г.
        </td>
      </tr>
      <ProductHead />
    </>
    }
    <tr key={product.id} ref={ref} className="product">
      <td>{product.index}</td>
      <td>{product.article}</td>
      <td>{product.index}</td>
      <td>{product.name}</td>
      <td>--</td>
      <td>796</td>
      <td>шт</td>
      <td className="right">{product.count}</td>
      <td className="right">{priceFormat(product.priceWithoutNds, 2)}</td>
      <td className="right">{priceFormat(product.summ - product.nds, 2)}</td>
      <td>без<br />акциза</td>
      <td>20%</td>
      <td className="right">{priceFormat(product.nds, 2)}</td>
      <td className="right">{priceFormat(product.summ, 2)}</td>
      <td>{product.countryCode || "-"}</td>
      <td>{product.countryName || "-"}</td>
      <td>{product.gtd || "-"}</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td></td>
    </tr>


  </>
}