import { AutocompleteArticle } from "features/Dashboard/AutocompleteArticle";
import React, { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import {
  Button,
  ButtonGroup,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { toast } from "react-toastify";
import Select from "react-select";

import { validHandler } from "utils/validation";
import { CreateNomenclatureModal } from "components/CreateNomenclatureModal";
import { CloseButton } from "components/CloseButton";
import { MySpinner } from "components/MySpinner";
import {
  addSupplyProduct,
  getCountry,
  updateSupplyProduct,
} from "services/apiSupply";
import { validGtd } from "utils/validGtd";
import { useUser } from "features/Users/UserContext";

const VALID = ["nomenclature", "summ", "count"];
const VALID_BAILOR = ["nomenclature", "count"];

export const AddProductModal = ({
  product = null,
  setProduct,
  supplyId,
  openModal,
  setOpenModal,
  setProducts,
  setCarriedOut,
  bailor,
  provider,
  warehouses,
}) => {
  const [article, setArticle] = useState("");
  const [loading, setLoading] = useState(false);
  const [brand, setBrand] = useState("");
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [summ, setSumm] = useState("");
  const [count, setCount] = useState(1);
  const [countryCode, setCountryCode] = useState("");
  const [countryName, setCountryName] = useState("");
  const [gtd, setGtd] = useState("");
  const [warehouse, setWarehouse] = useState(null);
  const [nomenclature, setNomenclature] = useState(null);
  const [errors, setErrors] = useState([]);
  const [needValid, setNeedValid] = useState(false);
  const [exist, setExist] = useState(0);
  const { user } = useUser();

  const getAndSetCountry = () => {
    getCountry(countryCode)
      .then((res) => {
        if (!res) {
          toast.error("Не найдена страна по указанному коду");
          return;
        }

        setCountryName(res.shortName);
      })
      .catch((e) => console.error(e));
  };

  useEffect(() => {
    if (!warehouse && warehouses.length > 0) {
      if (user?.branch === 2) {
        const warehouse = warehouses.find(item => item.alias === "transit");
        setWarehouse({ label: warehouse?.name, value: warehouse?.id });
      } else {
        setWarehouse({ label: warehouses[0]?.name, value: warehouses[0]?.id });
      }
    }

    if (setWarehouse) {

    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehouses]);

  const summInputHandler = (e) => {
    const summ = e.target.value.replace(",", ".").replace(" ", "");

    if (summ === "" || /^[0-9\b\.]+$/.test(summ)) {
      setSumm(summ);

      setPrice(((parseFloat(summ) * 100) / count || 1) / 100);
    }
  };

  const priceInputHandler = (e) => {
    // const price = e.target.value;
    // if (price === "" || /^[0-9\b]+$/.test(price)) {
    //   setPrice(price);
    // }
  };

  const countInputHandler = (e) => {
    const count = e.target.value;
    if (count === "" || /^[0-9\b]+$/.test(count)) {
      setCount(count);

      setPrice(((parseFloat(summ) * 100) / count || 1) / 100);
    }
  };

  const addProductHandler = () => {
    if (exist && count > exist) {
      toast.error(`Кол-во может быть не больше ${exist}`);
      return;
    }

    // if (bailor && !provider?.value) {
    //   toast.error(`Выберите поставщика!`);
    //   return;
    // }
    setNeedValid(true);

    const { valid, errors } = validHandler(
      {
        nomenclature,
        summ,
        count,
      },
      bailor ? VALID_BAILOR : VALID
    );

    setErrors(errors);

    if (!valid) return;

    setLoading(true);

    addSupplyProduct({
      nomenclature,
      supplyId,
      price,
      summ,
      count,
      countryCode,
      countryName,
      gtd,
      bailor,
      provider: provider?.value,
      warehouse: warehouse?.value,
    })
      .then((res) => {
        setProducts(res.products);
        setCarriedOut(res.carriedOut);
        setArticle("");
        setNomenclature(null);
        setBrand("");
        setName("");
        setPrice("");
        setSumm("");
        setCount("");
        setCountryCode("");
        setCountryName("");
        setGtd("");
        setProduct(null);
        toast.success("Товар успешно добавлен");
        setNeedValid(false);
        setOpenModal(false);
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateProductHandler = () => {
    if (exist && count > exist) {
      toast.error(`Кол-во может быть не больше ${exist}`);
      return;
    }

    setNeedValid(true);

    const { valid, errors } = validHandler(
      {
        nomenclature,
        summ,
        count,
      },
      bailor ? VALID_BAILOR : VALID
    );

    setErrors(errors);

    if (!valid) return;

    setLoading(true);

    updateSupplyProduct({
      id: product.id,
      nomenclature: {
        id: nomenclature,
      },
      supplyId,
      price,
      summ,
      count,
      countryCode,
      countryName,
      gtd,
      warehouse: warehouse?.value,
    })
      .then((res) => {
        setProducts(res.products);
        setArticle("");
        setNomenclature(null);
        setBrand("");
        setName("");
        setPrice("");
        setSumm("");
        setCount("");
        setCountryCode("");
        setCountryName("");
        setGtd("");
        setProduct(null);
        toast.success("Товар успешно сохранен");
        setNeedValid(false);
        setOpenModal(false);
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!product) return;
    setArticle(product.article);
    setNomenclature(product.nomenclature?.id);
    setBrand(product.brand);
    setName(product.name);
    setPrice(product.price);
    setSumm(product.summ);
    setCount(product.count);
    setCountryCode(product.countryCode || "");
    setCountryName(product.countryName || "");
    setGtd(product.gtd || "");
    setWarehouse({
      label: product.warehouse?.name,
      value: product.warehouse?.id,
    });
    setExist(product.exist);
  }, [product]);

  useEffect(() => {
    const { errors } = validHandler(
      {
        nomenclature,
        summ,
        count,
      },
      bailor ? VALID_BAILOR : VALID
    );
    setErrors(errors);
    // eslint-disable-next-line
  }, [article, brand, summ, name, count]);

  useEffect(() => {
    if (countryCode?.length < 3) return;
    getAndSetCountry();

    if (!validGtd(gtd)) {
      errors.gtd = "Невалидный ГТД";
    }
  }, [countryCode]);

  const selectedBrand = (brand) => {
    setBrand(brand?.name);
  };

  return (
    <Modal isOpen={openModal} toggle={() => setOpenModal(false)}>
      <ModalHeader>
        <div className="modal-title-text">
          {product ? `Редактировать товар` : `Добавить товар в поступление`}
        </div>
        <CloseButton onClick={() => setOpenModal(false)} />
      </ModalHeader>

      {loading && <MySpinner overlay />}

      <ModalBody className="m-3">
        <Row>
          <Col lg="6">
            <FormGroup>
              <Label>Артикул</Label>
              <ButtonGroup style={{ width: "100%" }}>
                <AutocompleteArticle
                  article={article}
                  brand={brand}
                  setArticle={setArticle}
                  setBrand={({ id, name }) => setBrand(name)}
                  setName={setName}
                  setNomenclature={setNomenclature}
                  setPrice={setPrice}
                  needValid={needValid}
                  errors={errors}
                  style={{ width: "218px" }}
                />
                <CreateNomenclatureModal
                  articleHandler={setArticle}
                  brandHandler={selectedBrand}
                  nameHandler={setName}
                  nomenclatureHandler={setNomenclature}
                />
              </ButtonGroup>
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <Label>Бренд</Label>
              <Input
                name="brand"
                placeholder="Бренд"
                value={brand}
                invalid={needValid && errors.hasOwnProperty("brand")}
                readOnly
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <FormGroup>
              <Label>Наименование</Label>
              <Input
                name="name"
                placeholder="Наименование"
                onChange={(e) => setName(e.target.value)}
                value={name}
                invalid={needValid && errors.hasOwnProperty("name")}
                readOnly
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="3">
            <FormGroup>
              <Label>Кол-во</Label>
              <Input
                name="count"
                placeholder="Кол-во"
                onChange={countInputHandler}
                value={count}
                invalid={needValid && errors.hasOwnProperty("count")}
              />
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup>
              <Label>Сумма</Label>
              <Input
                name="summ"
                placeholder="Сумма"
                onChange={summInputHandler}
                value={summ}
                invalid={needValid && errors.hasOwnProperty("summ")}
              />
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <Label>Цена</Label>
              <Input
                name="price"
                placeholder="Цена"
                onChange={priceInputHandler}
                value={price}
                invalid={needValid && errors.hasOwnProperty("price")}
                readOnly
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="2">
            <FormGroup>
              <Label>Код страны</Label>
              <Input
                name="countryCode"
                placeholder="Код страны"
                onChange={(e) => setCountryCode(e.target.value)}
                value={countryCode}
                invalid={needValid && errors.hasOwnProperty("countryCode")}
                maxLength="3"
              />
            </FormGroup>
          </Col>
          <Col lg="4">
            <FormGroup>
              <Label>Страна</Label>
              <Input
                name="countryName"
                placeholder="Страна"
                onChange={(e) => setCountryName(e.target.value)}
                value={countryName}
                invalid={needValid && errors.hasOwnProperty("countryName")}
                readOnly
              />
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <Label>ГТД</Label>
              <Input
                name="gtd"
                placeholder="ГТД"
                onChange={(e) => setGtd(e.target.value)}
                value={gtd}
                invalid={needValid && errors.hasOwnProperty("gtd")}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col lg="6">
            <FormGroup>
              <Label>Склад</Label>
              <Select
                name="warehouse"
                placeholder="Склад"
                className="react-select-container"
                classNamePrefix="react-select"
                options={warehouses.map((item) => {
                  return {
                    label: item.name,
                    value: item.id,
                  };
                })}
                value={warehouse}
                onChange={setWarehouse}
              />
            </FormGroup>
          </Col>
        </Row>
        <div className="d-flex justify-content-end">
          {product ? (
            <Button
              onClick={updateProductHandler}
              color="primary"
              className="button-wide"
            >
              Сохранить
            </Button>
          ) : (
            <Button
              onClick={addProductHandler}
              color="primary"
              className="button-wide"
            >
              Добавить
            </Button>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};
